<template>
  <CCard id="printArea">
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <List ref="list"
        :fields=fields
        :items.sync=appObfuscationList
        :loading.sync=loading
        showSearch
        :comboboxData="comboboxData"
        sorter
        :defaultSort=defaultSort
        showRegBtn
        showDeleteBtn
        showExcelBtn
        :excel=excel
        showPrintBtn
        itemsPerPageSelect
        :itemsPerPage=itemPerPage
        menuKey=203
      >
        <template #no="{index}">
            <td>
              {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
            </td>
        </template>

        <!--아이템별 버튼 재정의-->
        <template #show_buttons="{item, index}">
          <td class="py-2" >
            <CButton
              square
              size="sm"
              @click="downloadApp(item, index)"
              v-c-tooltip="'앱다운로드'"
              class="btn btn-primary"
            >
              <CIcon
                :content="$options.icons.cilDataTransferDown"
              />
            </CButton>
<!--            <CButton-->
<!--              size="sm"-->
<!--              square-->
<!--              @click="detailItem(item, index)"-->
<!--              v-c-tooltip="'상세보기'"-->
<!--              :class="[item.storeYn == '0' ? 'btn btn-primary btn-lft-margin':'btn btn-primary']"-->
<!--              >-->
<!--              <CIcon-->
<!--                :content="$options.icons.cilInfo"-->
<!--              />-->
<!--            </CButton>-->
            <CButton
              square
              size="sm"
              @click="deleteItem(item, index)"
              v-c-tooltip="'삭제'"
              v-if="$hasWritePermission('202')"
              class="btn btn-primary btn-lft-margin"
            >
              <CIcon
                :content="$options.icons.cilTrash"
              />
            </CButton>
<!--            <CButton-->
<!--              square-->
<!--              size="sm"-->
<!--              @click="showItemLog(item, index)"-->
<!--              v-c-tooltip="'앱 정책 감사 로그'"-->
<!--              class="btn btn-primary btn-lft-margin"-->
<!--            >-->
<!--              <CIcon-->
<!--                :content="$options.icons.cilHistory"-->
<!--              />-->
<!--            </CButton>-->
          </td>
          <CModal
            :show.sync="showNoLicenseModal"
            :closeOnBackdrop="false"
            :centered="true"
            title=""
            color="danger"
            addContentClasses="modal-max-width"
            size="lg"
          >
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol>
                    <h4>라이센스 오류</h4>
                    <p>앱 난독화를 위한 유효한 라이센스가 존재하지 않습니다.</p>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <template #footer>
              <CButton color="secondary" @click="closeNoLicenseModal">닫기</CButton>
            </template>
          </CModal>
        </template>
      </List>
      <page-link ref="page"
        :pageLinkCount=pagePerLink
        :listRowCount=itemPerPage
      />
<!--      <ModifyAppModal v-if="isOpenModifyAppModal"  :appKeyStr="appKeyStr" :appNmStr="appNmStr" @close-modal="afterModifyItems"/>-->
    </CCardBody>
  </CCard>
</template>

<script>
import List from "@/views/common/List.vue";
import PageLink from '@/views/common/PageLink.vue';
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import ModifyAppModal from './ModifyAppModal'
import { cilMagnifyingGlass, cilPencil, cilTrash, cilHistory, cilDataTransferDown, cilInfo} from '@coreui/icons'
// COREUI 아이콘 목록 : https://icons.coreui.io/icons/
export default {
  name: 'appObfuscation',
  icons: { cilMagnifyingGlass, cilPencil, cilTrash, cilHistory, cilDataTransferDown, cilInfo},
  components: {
    List,
    PageLink,
    CIcon,
    // ModifyAppModal,

  },
  computed: {
      // 목록에 들어갈 데이터
      appObfuscationList(){
        return this.$store.state.appObfuscation.appObfuscationList || []
      },
      appNmStr () {
        let appNmStr = ""
        let checkedList = this.$refs.list.getCheckedList()
        for(var i in checkedList){
          for(var j in this.appObfuscationList){
            if(this.appObfuscationList[j].appKey == checkedList[i]){
              appNmStr+=(appNmStr==""?"":",")+this.appObfuscationList[j].appNm
              break;
            }
          }
        }
        return appNmStr
      },
      appKeyStr(){
        let appKeyStr = ""
        let checkedList = this.$refs.list.getCheckedList()
        for(var i in checkedList)  appKeyStr+= (appKeyStr==""?"":",")+checkedList[i]
        return appKeyStr
      },
      totalCnt(){
        return this.$store.state.appObfuscation.appObfuscationListTotalCnt
      }

  },
  // 로컬변수생성
  data() {
		return{
      searchType:"appNm", // 콤보박스 디폴트 값(하기 comboboxData의 value로 설정)
      // 검색조건 설정
      comboboxData:[{label: '앱 이름', value: 'appNm'},{label: '패키지명', value:'packageNm'},{label: 'OS', value:'osType', codeYn: true}],
      // 그리드 컬럼 설정
      fields:[
          { key: 'check_box', label:'', _style: { width: '2%'}, mapping: 'appKey'},
          { key: 'no', label:'No', _style: { width: '2%'} },
          { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
          { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
          { key: 'appVersion', label:'버전', _style: { width: '10%'} },
          { key: 'osType', label:'OS', _style: { width: '5%'}, iconYn: true },
          { key: 'fileSize', label:'사이즈(MB)', _style: { width: '8%'} },
          // { key: 'storeYn', label:'스토어', _style: { width: '8%'}, codeYn:true },
          // { key: 'appTamperDetectYn', label:'App', sorter: false, _style: { width: '5%'}, codeYn:true, code:'detectYn', badgeYn:true},
          // { key: 'osTamperDetectYn', label:'OS', sorter: false, _style: { width: '5%'}, codeYn:true, code:'detectYn', badgeYn:true},
          // { key: 'debugDetectYn', label:'Debug', sorter: false, _style: { width: '5%'}, codeYn:true, code:'detectYn', badgeYn:true},
          { key: 'regDate', label:'등록일', _style: { width: '10%'} },
          {
            key: 'show_buttons',
            label: '',
            _style: { width: '13%'},
            sorter: false,
          },
      ],
      pagePerLink: 5, // 하단에 보여줄 페이지번호 개수
      defaultSort: "-regDate", // 기본 정렬 설정. 내림차순은 -memberName
      pageNum: 1, // 페이지번호
      itemPerPage:8,  // 한페이지당 보여줄 데이터수
      loading:false,
      alert:{
        noItem:"선택된 아이템이 없습니다.",
      },
      isOpenModifyAppModal: false,
      searchDate: [new Date(), new Date()],
      excel: {
        excelType : '2',  // 엑셀타입 (0: 검증로그, 1: 감사로그, 2: 앱 목록, 3: 앱정책감사로그, 4: 권한그룹 목록, 5: 관리자 목록)
        excelFileNm : '앱 목록'
      },
      showNoLicenseModal: false,
      allowObfuscationAppCnt: '',
    }
  },
  // 파라미터
  props: {
    caption: {
      default: '앱 난독화'
    },
  },
  mounted(){
    this.checkLicense()  // 라이센스 체크
    this.searchList()
  },
  methods: {
    async checkLicense() {
      try {
        await this.$store.dispatch('license/getLicenseInfo')
        var licenseInfo = this.$store.state.license.licenseInfo
        this.allowObfuscationAppCnt = licenseInfo.allowObfuscationAppCnt
        if (this.allowObfuscationAppCnt == 0) {
          this.showNoLicenseModal = true

        }
      } catch (error) {
        console.error('라이센스 정보 조회 실패:', error)
        this.$response(error)
      }
    },

    closeNoLicenseModal() {
      this.showNoLicenseModal = false
    },

    // 리스트조회
    async searchList(){
      let payload = this.$refs.list.getParams()
      this.loading=true
      try {
        await this.$store.dispatch('appObfuscation/getAppObfuscationList', payload)
        this.$refs.page.reloadPagination(this.totalCnt)
        this.loading=false
      } catch(error){
        this.$response(error)
        this.loading=false
      }
    },
    // 개별 상세보기 (<List> 내에 show_buttons인 Template 정의 필요 )
    // detailItem(item, index){
    //   this.$store.commit("dataStore/appObfuscation_appKey", item.appKey);
    //   this.$store.commit("dataStore/appObfuscation_mode", '0');
    //   this.$router.push({name: 'AppObfuscation'})
    // },
    // 개별 수정 (<List> 내에 show_buttons인 Template 정의 필요 )
    // modifyItem(item, index){
    //   this.$store.commit("dataStore/appObfuscation_appKey", item.appKey);
    //   this.$store.commit("dataStore/appObfuscation_mode", '1');
    //   this.$router.push({name: 'AppObfuscation'})
    // },
    // 개별 삭제 (<List> 내에 show_buttons인 Template 정의 필요 )
    async deleteItem(item, index){
      if(window.confirm("삭제하시겠습니까?")) {
        this.loading=true
        try {
            await this.$store.dispatch('appObfuscation/deleteAppObfuscation', item.appKey)
            alert("삭제하였습니다.")
            this.loading=false
            this.searchList()
        } catch(error){
            this.$response(error)
            this.loading=false
        }
      }
    },
    // 개별 로그보기 (<List> 내에 show_details인 Template 정의 필요 )
    // showItemLog(item, index){
    //   this.$store.commit("dataStore/appPolicyAuditLogList_appKey", item.appKey);
    //   this.$router.push({name: 'AppPolicyAuditLogList'})
    // },
    // 등록 (<List>에 showRegBtn 값 넘겨줘야함)
    regItems(){
      this.$router.push({name: 'RegistObfuscation'})
    },
    // 일괄수정 (<List>에 showModifyBtn 값 넘겨줘야함)
    // modifyItems(){
    //   var checkedList = this.$refs.list.getCheckedList()
    //   if(checkedList.length == 0){
    //     alert(this.alert.noItem)
    //   }
    //   else{
    //     this.isOpenModifyAppModal=true
    //   }
    // },
    // afterModifyItems(){
    //     this.isOpenModifyAppModal=false
    //     this.searchList()
    // },
    // 일괄삭제 (<List>에 showDeleteBtn 값 넘겨줘야함)
    async deleteItems(){
      var checkedList = this.$refs.list.getCheckedList();
      if(checkedList.length == 0){
        alert(this.alert.noItem)
      }
      else{
        if(window.confirm(checkedList.length+"건의 앱 난독화 정보를 일괄 삭제하시겠습니까?")) {
          this.loading=true
          try {
              await this.$store.dispatch('appObfuscation/deleteAppObfuscation', this.appKeyStr)
              alert("삭제하였습니다.")
              this.loading=false
              this.searchList()
          } catch(error){
              this.$response(error)
              this.loading=false
          }
        }
      }
    },
    // 앱 다운로드
    async downloadApp(item, index){
      this.loading =true
      await this.$downloadObfuscationApp(item.appKey)
      this.loading =false
    },
  }
}
</script>
